import React from 'react'

const Footer = props => (
  <footer id="footer">
    <ul className="icons">
      <li>
        <a href="https://www.facebook.com/palette.sartoise" className="icon brands alt fa-facebook-f" target="_blank">
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a href="tel:+32 486 462566" className="icon solid alt fa-phone">
          <span className="label">Téléphone</span>
        </a>
      </li>
      <li>
        <a href="mailto:stephane.draux@skynet.be" className="icon solid alt fa-envelope">
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li>&copy; Palette Sartoise 2020. All rights reserved.</li>
      <li>
        Images: <a href="https://unsplash.com" target="_blank">Unsplash</a> & <a href="https://www.jr-photo.be" target="_blank">JR-Photo</a>
      </li>
    </ul>
  </footer>
)

export default Footer
